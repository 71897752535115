import { slideUp, slideDown } from "@/javascript/components/animations/slide"
import { closest } from "@/javascript/components/tools/closest"
import loadBefore from "@/javascript/components/load"

export default class FlashMessages {
  constructor () {
    this.container = document.querySelector('.application-flash')
    this.template = this.container.querySelector('.template').innerHTML
    this._bind()
  }

  notice (msg) {
    this.message('notice', msg)
  }

  alert (msg) {
    this.message('alert', msg)
  }

  clear (level) {
    this.container.querySelectorAll('.flash.' + level).forEach((message) => {
      message.parentNode.removeChild(message)
    })
  }

  message (level, msg) {
    const message = document.createElement('div')
    message.classList.add('flash', level)
    message.dataset.flashTarget = 'flash'
    message.innerHTML = this.template
    const messageContainer = message.querySelector('.message')
    messageContainer.innerHTML = msg
    this.container.append(message)
    message.classList.add('fade-in-down')
  }

  _bind() {
    this.container.addEventListener('click', (e) => {
      const closeButton = closest(e.target, '.close-button')
      if(!closeButton) {
        return
      }

      const message = closest(closeButton, '.flash')
      message.addEventListener('animationend', () => {
        message.remove()
      })
      message.classList.add('fade-out-down')
    })
  }
}

loadBefore(() => {
  const flashContainer = document.querySelector('.application-flash')
  new FlashMessages(flashContainer)
})
